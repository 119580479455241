import React from 'react';

// Total Recharge Amount Icon
export const TotalRechargeAmountIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><g fill="#cae3f7" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.1 8.004q-.085-.005-.181-.004h-2.525c-2.068 0-3.837 1.628-3.837 3.75s1.77 3.75 3.837 3.75h2.525q.096.001.182-.004a1.755 1.755 0 0 0 1.645-1.628c.004-.06.004-.125.004-.185V9.817c0-.06 0-.125-.004-.185a1.755 1.755 0 0 0-1.645-1.628m-2.928 4.746c.532 0 .963-.448.963-1s-.431-1-.963-1c-.533 0-.964.448-.964 1s.431 1 .964 1"/><path d="M20.918 17a.22.22 0 0 1 .221.278c-.2.712-.519 1.32-1.03 1.83c-.749.75-1.698 1.081-2.87 1.239c-1.14.153-2.595.153-4.433.153h-2.112c-1.838 0-3.294 0-4.433-.153c-1.172-.158-2.121-.49-2.87-1.238c-.748-.749-1.08-1.698-1.238-2.87C2 15.099 2 13.644 2 11.806v-.112C2 9.856 2 8.4 2.153 7.26c.158-1.172.49-2.121 1.238-2.87c.749-.748 1.698-1.08 2.87-1.238C7.401 3 8.856 3 10.694 3h2.112c1.838 0 3.294 0 4.433.153c1.172.158 2.121.49 2.87 1.238c.511.512.83 1.119 1.03 1.831a.22.22 0 0 1-.221.278h-2.524c-2.837 0-5.337 2.24-5.337 5.25s2.5 5.25 5.337 5.25zM5.75 7a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5z"/></g></svg>
);

// Customer Count Icon
export const CustomerCountIcon = () => (

<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 12 12"><path fill="#cae3f7" d="M6 1a2 2 0 1 0 0 4a2 2 0 0 0 0-4m2.5 5h-5A1.5 1.5 0 0 0 2 7.5c0 1.116.459 2.01 1.212 2.615C3.953 10.71 4.947 11 6 11s2.047-.29 2.788-.885C9.54 9.51 10 8.616 10 7.5A1.5 1.5 0 0 0 8.5 6"/></svg>    

);

export const AstrologerCountIcon = () => (

    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 128 128"><path fill="#cae3f7" d="M115.69 56.04c-3.19 0-29.68 3.03-43.73 4.67c11.09-8.78 31.96-25.37 34.23-27.63c7.1-7.11 4.64-11.7 2.54-13.8c-3.48-3.48-8.55-2.72-13.8 2.54c-2.26 2.26-18.85 23.13-27.63 34.22c1.63-14.05 4.67-40.54 4.67-43.73C71.97 2.25 66.98.75 64.01.75s-7.96 1.5-7.96 11.56c0 3.19 3.03 29.69 4.66 43.73c-8.78-11.09-25.37-31.96-27.63-34.22c-5.26-5.26-10.32-6.02-13.8-2.54c-2.14 2.14-2.71 4.94-1.61 7.88c.7 1.87 2.1 3.86 4.15 5.92c2.26 2.26 23.13 18.84 34.22 27.62c-14.05-1.63-40.54-4.67-43.74-4.67C2.25 56.04.75 61.03.75 64s1.5 7.96 11.55 7.96c3.2 0 29.68-3.03 43.73-4.66c-11.09 8.78-31.96 25.37-34.22 27.63c-2.05 2.05-3.45 4.04-4.15 5.91c-1.11 2.95-.53 5.75 1.61 7.88c1.42 1.41 3.15 2.17 5.01 2.17c2.72 0 5.67-1.58 8.79-4.7c2.26-2.26 18.85-23.14 27.63-34.23c-1.63 14.05-4.66 40.54-4.66 43.74c0 10.05 4.99 11.55 7.96 11.55s7.96-1.5 7.96-11.55c0-3.2-3.03-29.68-4.67-43.73c8.78 11.09 25.37 31.96 27.63 34.22c3.12 3.12 6.08 4.7 8.8 4.7c1.86 0 3.59-.75 5-2.17c2.1-2.1 4.57-6.69-2.54-13.8c-2.26-2.26-23.13-18.85-34.23-27.63c14.05 1.63 40.54 4.66 43.73 4.66c10.06 0 11.56-4.98 11.56-7.96c.01-2.96-1.49-7.95-11.55-7.95"/></svg>
);

export const CourseComponent = () => (

    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 32 32"><path fill="#cae3f7" d="M26.68 7.858a.6.6 0 0 0-.607-.083l-9.66 4.183c-.42.183-.946.27-1.486.27c-.753.003-1.532-.172-2.075-.41a2.5 2.5 0 0 1-.47-.26q.061-.03.145-.064l8.786-3.804l1.31.56V6.613a.78.78 0 0 0-.283-.612a.59.59 0 0 0-.605-.083l-9.66 4.183c-.298.12-.554.268-.77.483a1.33 1.33 0 0 0-.395.934c0 .01.003.027.003.027v14.73l-.002.02l.003.01v.016h.002c.02.515.28.843.528 1.075c.78.688 2.09 1.073 3.484 1.093c.66 0 1.33-.1 1.95-.366l9.663-4.184c.255-.11.422-.383.422-.692V8.47a.78.78 0 0 0-.283-.612m-6.127-2.8c-.017-.22-.108-.43-.27-.556a.6.6 0 0 0-.607-.083L10.016 8.6c-.42.182-.947.27-1.486.27c-.753.002-1.532-.173-2.075-.412a2.5 2.5 0 0 1-.47-.258q.061-.03.145-.064l8.787-3.804l1.31.56V3.257a.78.78 0 0 0-.284-.612a.6.6 0 0 0-.606-.083l-9.66 4.184c-.298.12-.553.267-.77.483a1.33 1.33 0 0 0-.394.934c0 .012.003.028.003.028v14.777h.002c.02.515.28.843.528 1.075c.78.688 2.09 1.072 3.485 1.092a5.6 5.6 0 0 0 1.127-.122V11.544c-.01-.7.27-1.372.762-1.856a3.5 3.5 0 0 1 1.19-.756z"/></svg>
    
);
export const LiveComponent = () => (

    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path fill="#cae3f7" d="M5.99 4.929a.75.75 0 0 1 0 1.06a8.5 8.5 0 0 0 0 12.021a.75.75 0 0 1-1.061 1.06c-3.905-3.905-3.905-10.236 0-14.141a.75.75 0 0 1 1.06 0m13.081 0c3.905 3.905 3.905 10.237 0 14.142a.75.75 0 0 1-1.06-1.06a8.5 8.5 0 0 0 0-12.022a.75.75 0 1 1 1.06-1.06M8.818 7.757a.75.75 0 0 1 0 1.06a4.5 4.5 0 0 0 0 6.365a.75.75 0 0 1-1.06 1.06a6 6 0 0 1 0-8.485a.75.75 0 0 1 1.06 0m7.425 0a6 6 0 0 1 0 8.485a.75.75 0 1 1-1.061-1.06a4.5 4.5 0 0 0 0-6.364a.75.75 0 0 1 1.06-1.06M12 10.5a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3"/></svg>
);
export const demoComponent = () => (

    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path fill="#cae3f7" d="M18 18q.625 0 1.063-.437T19.5 16.5t-.437-1.062T18 15t-1.062.438T16.5 16.5t.438 1.063T18 18m0 3q.8 0 1.425-.35t1.05-.975q-.5-.4-1.137-.588T18 18.9t-1.338.188t-1.137.587q.425.625 1.05.975T18 21M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v6.7q-.475-.225-.975-.387T19 11.075V5H5v14h6.05q.075.55.238 1.05t.387.95zm0-3v1V5v6.075V11zm2-1h4.075q.075-.525.238-1.025t.362-.975H7zm0-4h6.1q.8-.75 1.788-1.25T17 11.075V11H7zm0-4h10V7H7zm11 14q-2.075 0-3.537-1.462T13 18t1.463-3.537T18 13t3.538 1.463T23 18t-1.463 3.538T18 23"/></svg>
);

export const deleteComponent = () => (

    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path fill="#10395D" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"/></svg>
);

// Add more icons as needed
