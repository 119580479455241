import React from 'react';

const WaitingListHistory =() =>{

    return(
        <div>
            Waiting List History Page
        </div>
    );
}
export default WaitingListHistory;